<template>
    <div class="email-wysiwyg">
        <div class="tool-btns">
            <button
                @click="toggleBold"
                title="Bold"
                type="button"
                :class="{'active-wysiwyg': isActive('bold')}"
            >
                <span class="material-icons-outlined">format_bold</span>
            </button>
            <button
                @click="toggleItalics"
                title="Italicize"
                type="button"
                :class="{'active-wysiwyg': isActive('italic')}"
            >
                <span class="material-icons-outlined">format_italic</span>
            </button>
            <button
                @click="toggleUnderline"
                title="Underline"
                type="button"
                :class="{'active-wysiwyg': isActive('underline')}"
            >
                <span class="material-icons-outlined">format_underlined</span>
            </button>
            <button
                @click="toggleStrikethrough"
                title="Strikethrough"
                type="button"
                :class="{'active-wysiwyg': isActive('strike')}"
            >
                <span class="material-icons-outlined">strikethrough_s</span>
            </button>
            <button
                @click="toggleHighlight"
                title="Highlight"
                type="button"
                :class="{'active-wysiwyg': isActive('highlight')}"
            >
                <span class="material-icons-outlined">format_color_fill</span>
            </button>
            <div class="vert-divider"></div>
            <button
                @click="setParagraph"
                title="Paragraph"
                type="button"
                :class="{'active-wysiwyg': isActive('paragraph')}"
                class="weight-700"
            >
                P
            </button>
            <button
                @click="setHeading(1)"
                title="Heading 1"
                type="button"
                :class="{'active-wysiwyg': isActive('heading', { level: 1 })}"
                class="weight-700"
            >
                H1
            </button>
            <button
                @click="setHeading(2)"
                title="Heading 2"
                type="button"
                :class="{'active-wysiwyg': isActive('heading', { level: 2 })}"
                class="weight-700"
            >
                H2
            </button>
            <div class="vert-divider"></div>
            <button
                @click="toggleBulletList"
                title="Bulleted List"
                type="button"
                :class="{'active-wysiwyg': isActive('bulletList')}"
            >
                <span class="material-icons-round">format_list_bulleted</span>
            </button>
            <button
                @click="toggleOrderedList"
                title="Ordered List"
                type="button"
                :class="{'active-wysiwyg': isActive('orderedList')}"
            >
                <span class="material-icons-round">format_list_numbered</span>
            </button>
            <div class="vert-divider"></div>
            <button
                type="button"
                @click="undo"
                title="Undo"
                :disabled="!canUndo"
            >
                <span class="material-icons-outlined">undo</span>
            </button>
            <button
                type="button"
                @click="redo"
                title="Redo"
                :disabled="!canRedo"
            >
                <span class="material-icons-outlined">redo</span>
            </button>
        </div>
<!--        <button @click="toggleTaskList" title="Task List">Task List</button>-->
        <EditorContent :editor="editor" />
    </div>
</template>

<script>
    import { Editor, EditorContent } from '@tiptap/vue-2';
    import StarterKit from '@tiptap/starter-kit';
    import Highlight from '@tiptap/extension-highlight';
    import Underline from '@tiptap/extension-underline';
    import Link from '@tiptap/extension-link';
    import TaskList from '@tiptap/extension-task-list';
    import TaskItem from '@tiptap/extension-task-item';

    export default {
        name: 'Wysiwyg',
        components: {
            EditorContent,
        },
        props: {
            value: {
                type: String,
                default: '',
            }
        },
        data() {
            return {
                editor: null,
            };
        },
        computed: {
            canUndo() {
                return this.editor?.can().undo() ?? false;
            },
            canRedo() {
                return this.editor?.can().redo() ?? false;
            },
        },
        methods: {
            toggleBold() {
                this.editor.chain().focus().toggleBold().run();
            },
            toggleItalics() {
                this.editor.chain().focus().toggleItalic().run();
            },
            toggleUnderline() {
                this.editor.chain().focus().toggleUnderline().run();
            },
            toggleStrikethrough() {
                this.editor.chain().focus().toggleStrike().run();
            },
            toggleHighlight() {
                this.editor.chain().focus().toggleHighlight().run();
            },
            setParagraph() {
                this.editor.chain().focus().setParagraph().run();
            },
            setHeading(level = 1) {
                this.editor.chain().focus().toggleHeading({ level }).run();
            },
            toggleBulletList() {
                this.editor.chain().focus().toggleBulletList().run();
            },
            toggleOrderedList() {
                this.editor.chain().focus().toggleOrderedList().run();
            },
            toggleTaskList() {
                this.editor.chain().focus().toggleTaskList().run();
            },
            undo() {
                this.editor.chain().focus().undo().run();
            },
            redo() {
                this.editor.chain().focus().redo().run();
            },
            isActive(attr, options) {
                if (options) {
                    return this.editor?.isActive(attr, options) ?? false;
                }

                return this.editor?.isActive(attr) ?? false;
            }
        },
        watch: {
            value(newVal) {
                if (this.editor.getHTML() === newVal) {
                    return;
                }

                this.editor.commands.setContent(newVal, true);
            }
        },
        mounted() {
            this.editor = new Editor({
                content: this.value,
                extensions: [
                    StarterKit,
                    Highlight,
                    Underline,
                    Link,
                    TaskList,
                    TaskItem,
                ],
                onUpdate: () => {
                    this.$emit('input', this.editor.getHTML());
                    this.$emit('rawText', this.editor.getText());
                }
            });
        },
        beforeDestroy() {
            if (this.editor) {
                this.editor.destroy();
            }
        },
    };
</script>
