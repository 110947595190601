
<template>
  <div id="defaultRTE">
    <ejs-richtexteditor ref="defaultRTE" :height="height ? height: 340" v-model="templateData" :actionComplete="actionHandler">
    </ejs-richtexteditor>
  </div>
</template>

<script>
    import Vue from 'vue';
    import { RichTextEditorPlugin, Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar } from '@syncfusion/ej2-vue-richtexteditor';

    Vue.use(RichTextEditorPlugin);

    export default {
        props: {
            html: {

            },
            height: {
                type: Number
            }
        },
        data(){
            return {
                template: null,
            }
        },
        computed: {
            templateData: {
                get: function(){
                    //Get the original prop
                    return this.html
                },
                set: function(value){
                    //Emit back up the new value.
                     this.$emit('getRtxHtml', value);
                }
            }
        },
        methods:{
            //This is useful only for any actions committed on the toolbar.
            actionHandler(){

             
            }
        },
        provide: {
            richtexteditor:[Toolbar, Link, Image, Count, HtmlEditor, QuickToolbar]
        }
    }
</script>
