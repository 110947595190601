<template>
    <div>
        <form @submit.prevent="updateRecord">
            <!-- paste from hq below here -->

            <div class="">
                <h1>Email Template Details</h1>

                <div class="">
                    <div class="">
                        <Input
                            type="text"
                            name="email_template_title"
                            id="email_template_title"
                            placeholder="Email Template Title"
                            v-model="record_data.email_template_title"
                            label="Email Template Title"
                            :disabled="!isEditable"
                        />
                    </div>
                </div>
                <div class="fullwidth bottom-20">
                    <br />
                    <!--                    <RichTextEditor :html="record_data.html" @getRtxHtml="appendHtml" v-if="isEditable"/>-->
                    <Wysiwyg v-model="record_data.html" v-if="isEditable" />
                </div>
                <div class="" v-if="0">
                    <Input
                        type="text"
                        name="level"
                        id="level"
                        placeholder="Level"
                        v-model="record_data.level"
                        label="Level"
                        :disabled="!isEditable"
                    />
                </div>

                <!--form_extra-->
            </div>

            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    import RichTextEditor from '@/components/general/richTextEditor/RichTextEditor.vue';
    import Wysiwyg from '@/components/general/wysiwyg/Wysiwyg';
    import { debounce } from 'lodash';
    const api_root = 'email_templates';

    export default {
        components: { Wysiwyg, RichTextEditor },
        props: {
            record_id: [String, Number]
        },
        data() {
            return {
                name: 'EmailTemplateDetail',
                test: '',
                record_data: {
                    active: 0,
                    email_template_title: '',
                    html: '',
                    level: ''
                },
                isEditable: 1,
                record_actions: {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`
                }
            };
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id));

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record');
                        return this.$router.replace({ name: 'NotFound' });
                    } else {
                        this.record_data = res.data.info[0];
                    }
                }
            },
            updateRecord: debounce(async function(e) {
                e.preventDefault();
                const isValid = await this.validateForm();

                if (isValid) {
                    try {
                        const without_p_tags = this.record_data.html
                            .replace(/<p>/i, '')
                            .replace(/<\/p>$/i, '')
                            .replace(/<p>/gi, '<br \/>')
                            .replace(/<\/p>/gi, '')
                        // if it is not wrapped in pre tags, add them

                        // if (!without_p_tags.match(/^<div>/)) {
                        //     this.record_data.html = `<div>${without_p_tags}</div>`;
                        // }


                        const res = await this.$api.put(this.record_actions.updateRecord(this.record_id), {
                            ...this.record_data,
                            html: without_p_tags
                        });
                        const record_id = parseInt(res.data.recId[0]);
                        this.$toasted.success('Successfully updated Record.');

                        if(record_id){
                            await this.$router.push({ name: this.name, params: { record_id: record_id } })
                        }

                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.');
                    }
                }
            }, 2000, {leading: true, trailing: false}),
            async validateForm() {
                const isValid = 1; // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.');
                }

                return isValid;
            },
            appendHtml(value) {
                this.record_data.html = value;
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            async $route() {
                await this.init();
            }
        }
    };
</script>